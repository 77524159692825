import axios from 'axios';

import log from 'Utils/logger';
import qs from 'qs';

import CONFIG from 'Config';
import * as RoundUpsActionTypes from './constants';
import * as AppActions from "Flux/app/actions";

export function setRoundUpsShopperData( userData ) {
  console.log('setAcceptedRoundUpsTerms function in flux roundups actions triggered with content: ', userData)
  return {
    type: RoundUpsActionTypes.SET_ROUNDUPS_SHOPPER_DATA,
    payload: userData,
  };
}

export function unSetRoundUpsShopperData() {
  console.log('setAcceptedRoundUpsTerms function in flux roundups actions triggered')
  return {
    type: RoundUpsActionTypes.UNSET_ROUNDUPS_SHOPPER_DATA,
    //payload: userData,
  };
}


export function setAcceptedRoundUpsTerms(confirmation) {
  console.log('setAcceptedRoundUpsTerms function in flux roundups actions triggered with content: ', confirmation)
  return {
    type: RoundUpsActionTypes.SET_ACCEPTED_ROUNDUPS_TERMS,
    payload: confirmation,
  };
}

export function setAcceptedContributeNowTerms(confirmation) {
  console.log('setAcceptedContributeNowTerms function in flux roundups actions triggered with content: ', confirmation)
  return {
    type: RoundUpsActionTypes.SET_ACCEPTED_CONTRIBUTE_NOW_TERMS,
    payload: confirmation,
  };
}

export function setContributeNowEnabled(confirmation) {
  console.log('setAcceptedContributeNowTerms function in flux roundups actions triggered with content: ', confirmation)
  return {
    type: RoundUpsActionTypes.SET_ENABLED_CONTRIBUTE_NOW,
    payload: confirmation,
  };
}

export function setConfirmedContactDetails(confirmation) {
  console.log('setConfirmedContactDetails function in flux roundups actions triggered with content: ', confirmation)
  return {
    type: RoundUpsActionTypes.SET_CONFIRMED_CONTACT_DETAILS,
    payload: confirmation,
  };
}

export function setWeeklyLimit(weeklyLimit) {
  console.log('setWeeklyLimit function in flux roundups actions triggered with content: ', weeklyLimit)
  return {
    type: RoundUpsActionTypes.SET_WEEKLY_LIMIT,
    payload: weeklyLimit,
  };
}

export function setRoundUpAmount(roundUpAmount) {
  console.log('setRoundUpAmount function in flux roundups actions triggered with content: ', roundUpAmount)
  return {
    type: RoundUpsActionTypes.SET_ROUNDUP_AMOUNT,
    payload: roundUpAmount,
  };  
}

export function setRegisteredForRoundUps (status) {
  console.log('setRegisteredForRoundUps function in flux roundups actions triggered with content: ', status)
  return {
    type: RoundUpsActionTypes.SET_REGISTERED_FOR_ROUNDUPS,
    payload: status,
  };
}

export function setRoundUpsLinkedAccounts (accounts) {
  console.log('setRoundUpsLinkedAccounts function in flux roundups actions triggered with content: ', accounts)
  return {
    type: RoundUpsActionTypes.SET_ROUNDUPS_LINKED_ACCOUNTS,
    payload: accounts,
  };
}

export function setContributeNowAmount (newContributeNowAmount) {
  console.log('setContributeNowAmount function in flux roundups actions triggered with newContributeNowAmount: ', newContributeNowAmount)
  return {
    type: RoundUpsActionTypes.SET_CONTRIBUTE_NOW_AMOUNT,
    payload: newContributeNowAmount,
  };
}

export function setContributeNowContinueNotification (status) {
  console.log('setContributeNowContinueNotification function in flux roundups actions triggered with content: ', status)
  return {
    type: RoundUpsActionTypes.SET_CONTRIBUTE_NOW_CONTINUE_NOTIFICATION,
    payload: status,
  };
}

export function isFetchingRoundUpsShopperData( isFetchingStatus ) {
  console.log('isFetchingRoundUpsShopperData function in flux roundups actions triggered with content: ', isFetchingStatus)
  return {
    type: RoundUpsActionTypes.SET_IS_FETCHING_ROUNDUPS_SHOPPER_DATA,
    payload: isFetchingStatus,
  };
}

export function fetchRoundUpsShopperData() {
  console.log('fetchRoundUpsShopperData function in flux roundups actions triggered');
  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/shopper-data`,
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
  });
}

export function syncShopperDataFromOpenBank() {
  console.log('syncShopperDataFromOpenBank function in flux roundups actions triggered');
  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/sync-shopper-data`,
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
  });
}


export function updateConfirmedContactDetails( updatedState ) {
  console.log('updateConfirmedContactDetails function in flux roundups actions triggered with content: ', updatedState);
  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/confirm-contact-details`,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
    data: { 'confirm': updatedState },
  });
}

export function updateAcceptedRoundUpsTerms( updatedState ) {
  console.log('updateAcceptedRoundUpsTerms function in flux roundups actions triggered with payload: ', updatedState );
  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/accept-roundups-terms`,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
    data: { "accept": updatedState },
  });
}

export function updateAcceptedContributeNowTerms( updatedState ) {
  console.log('updateAcceptedContributeNowTerms function in flux roundups actions triggered with payload: ', updatedState );
  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/accept-contribute-now-terms`,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
    data: { "accept": updatedState },
  });
}

export function registerNewRoundupsShopper() {
  console.log('requestConsentLink function in flux roundups actions triggered');
  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/register-shopper`,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
  });
}

export function requestConsentLink() {
  console.log('requestConsentLink function in flux roundups actions triggered');
  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/request-consent-link`,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
  });
}

export function deleteBankConnection() {
  console.log('deleteBankConnection function in flux roundups actions triggered');
  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/delete-bank-connection`,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
  });
}

export function enableAccountRoundUps(account_id, newRoundUpEnabledStatus) {
  console.log('enableAccountRoundUps function in flux roundups actions triggered');
  console.log('enableAccountRoundUps account_id in flux roundups actions is: ', account_id);
  console.log('enableAccountRoundUps newRoundUpEnabledStatus in flux roundups actions is: ', newRoundUpEnabledStatus);
  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/enable-roundups`,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
    data: { 
            "linked_account_id": account_id,
            'enabled': newRoundUpEnabledStatus, 
          },
  });
}

export function enableAccountDebit(account_id, newDebitEnabledStatus) {
  console.log('enableAccountDebit function in flux roundups actions triggered');
  console.log('enableAccountDebit account_id in flux roundups actions is: ', account_id);
  console.log('enableAccountDebit newRoundUpEnabledStatus in flux roundups actions is: ', newDebitEnabledStatus);
  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/enable-debits`,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
    data: { 
            "linked_account_id": account_id,
            'enabled': newDebitEnabledStatus, 
          },
  });
}


export function fetchActivePromoCodes() {
  console.log('3.0 fetchActivePromoCodes function in flux roundups actions triggered');
  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/shopper-promo-codes`,
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
  });
}

export function checkPromoCodeValid( check_code, type ) {
  console.log('3.0 checkPromoCodeValid function in flux roundups actions triggered with check_code: ', check_code);
  console.log('3.1 checkPromoCodeValid function in flux roundups actions triggered with type: ', type);
  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/promo-code/${type}`,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
    data: { 
      'promo_code': check_code, 
    },
  });
}

export function activatePromoCode( code, type ) {
  console.log('3.0 activatePromoCode function in flux roundups actions triggered with code: ', code);
  console.log('3.1 activatePromoCode function in flux roundups actions triggered with type: ', type);
  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/promo-code/${type}/activate`,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
    data: { 
      'promo_code': code, 
    },
  });
}

export function updateRoundUpAmounts( roundUpToNearest ) {
  //return () => roundUpToNearest;
  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/set-roundup-amount`,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
    data:  
      {"amount": roundUpToNearest },
  });
}

export function updateWeeklyLimit( newWeeklyLimit ) {
  //return () => weeklyLimit;
  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/set-weekly-limit`,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
    data: {
       "amount": newWeeklyLimit, 
    },
  });
}

export function payFixedAmount( contributeNowAmount ) {

  console.log('payFixedAmount function in flux roundups actions triggered with contributeNowAmount: ', contributeNowAmount);

  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/pay-fixed-amount`,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
    data: {
       "amount": contributeNowAmount, 
    },
  });
}

export function updateContributeNowAmount( newContributeNowAmount ) {

  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/set-contribute-now-amount`,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
    data: {
       "amount": newContributeNowAmount, 
    },
  });
}

export function updateContributeNowEnabled( updatedState ) {

  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/set-contribute-now-enabled`,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
    data: {
       "enabled": updatedState, 
    },
  });
}

export function updateContributeNowContinueNotification( updatedState ) {

  return () => axios({
    url: `${CONFIG.apiV3Url}/roundups/set-pre-contribute-notification`,
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth_token')
    },
    data: {
       "confirm": updatedState, 
    },
  });
}

