import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import log from 'Utils/logger';
import classNames from 'classnames';

import styles from './index.css.js';

import IconButton from 'SharedComponents/icon-button';
import DialogTrigger from 'SharedComponents/dialog-trigger';
import ActionButton from 'SharedComponents/action-button';
import SignInForm from 'SharedComponents/sign-in-form';
import Avatar from 'SharedComponents/avatar';
import Menu from 'SharedComponents/menu';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';

import logo from 'Images/BYS_Default_logo_780x120.png';

import * as AuthenticationActions from 'Flux/authentication/actions';
import * as AppActions from 'Flux/app/actions';
import * as RoundUpsActions from 'Flux/round-ups/actions';
import * as RoundUpsTransactionsActions from 'Flux/transactions-round-ups/actions';
import * as ShoppingActions from 'Flux/shopping/actions';

const NavigationBarWrapper = styled.div`${styles}`;

const NavigationBar = ({ sticky, minimal }) => {
  const authentication = useSelector((state) => state.authentication);
  const app = useSelector((state) => state.app);
  //const roundups = useSelector((state) => state.roundups);
  const roundupLinkedAccounts = useSelector((state) => state.roundups.linkedAccounts);

  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [visibleSection, setVisibleSection] = useState(null);
  const ref = useRef(null);
  const [isDialogMenuOpen, setIsDialogMenuOpen] = useState(false);

  // 1. State to Track Dispatch
  const [hasDispatchedPrompt, setHasDispatchedPrompt] = useState(false);

  useEffect(() => {
    // 2. Early Dispatch with Guard
    if (!hasDispatchedPrompt && !app.hasBeforeInstallPromptBeenFired){
      console.log('***** READY TO DISPATCH BEFORE INSTALL PROMPT *****')
      dispatch(AppActions.setBeforeInstallPromptFire(true));
      setHasDispatchedPrompt(true);
    }
  }, [dispatch, hasDispatchedPrompt]);

  const onBeforeInstallPrompt = (event) => {
    console.log('Navigation Bar onBeforeInstallPrompt triggered to dispatc true to store');
    event.preventDefault();
    window.deferredInstallPrompt = event;
    //dispatch(AppActions.setBeforeInstallPromptFire(true));
  };

  useEffect(() => {
    console.log('Setting up beforeinstallprompt listener');
    window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt, true);
    return () => {
      console.log('Removing beforeinstallprompt listener');
      window.removeEventListener('beforeinstallprompt', onBeforeInstallPrompt, true);
    };
  }, []);
  

  const onInstallButtonClicked = () => {
    window.deferredInstallPrompt.prompt();

    window.deferredInstallPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
        log({
          event: 'User ACCEPTED the A2HS prompt from navigation-bar',
          shopper_id: authentication.user.shopper_id,
        });
      } else {
        console.log('User dismissed the A2HS prompt');
        log({
          event: 'User DISMISSED the A2HS prompt from navigation-bar',
          shopper_id: authentication.user.shopper_id,
        });
      }
      window.deferredInstallPrompt = null;
      dispatch(AppActions.setBeforeInstallPromptFire(false));
    });
  };

  const scrollToId = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleIntersection = (entries) => {
    let highestRatio = 0;
    let currentVisibleSection = null;

    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio > highestRatio) {
        highestRatio = entry.intersectionRatio;
        currentVisibleSection = entry.target.id;
      }
    });

    setVisibleSection(currentVisibleSection);
  };

  // IntersectionObserver setup
  useEffect(() => {
    const targetDivs = ['why-bys', 'how-it-works']; // IDs of your sections
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
      rootMargin: '25%',
    });

    targetDivs.forEach((id) => {
      const element = document.getElementById(id);
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, []); 

  



  // useEffect(() => {
  //   if (!authentication.user) { // Check if logout was successful
  //     navigate('/login');
  //   } 
  // }, [authentication.user]) 
  

  return (
    <NavigationBarWrapper isDialogMenuOpen={isDialogMenuOpen}>
      <div className="actions-container">
        <Link className="logo-positioning" to="/">
          {app.partnerContent ? (
            parseInt(app.partnerContent.partner_member_status) >= 2 ? (
              <div className={classNames('logo-posn2', { minimal: minimal })}>
                <img
                  className={classNames('img')}
                  src={app.partnerContent.partner_logo}
                  alt={app.partnerContent.partner_name}
                />
              </div>
            ) : (
              <div className="multi-logo-display">
                <div className={classNames('logo-posn1', { minimal: minimal })}>
                  <img className={classNames('img')} src={logo} alt="logo1" />
                </div>
                <div className={classNames('vertical-line', { minimal: minimal })}></div>
                <div className={classNames('logo-posn2', { minimal: minimal })}>
                  <img
                    className={classNames('img')}
                    src={app.partnerContent.partner_logo}
                    alt={app.partnerContent.partner_name}
                  />
                </div>
              </div>
            )
          ) : null}
        </Link>



        <div className="links">

        {!authentication?.user && !minimal ? (
            <Link key="how-it-works" to="/#how-it-works" className={classNames('link', { active: visibleSection === 'how-it-works' })} onClick={() => scrollToId('how-it-works')}>
              <span>How&nbsp;it&nbsp;Works</span>
            </Link>
          ) : null}
          { authentication?.user && !minimal ? (
              <Link key="dashboard" to="/dashboard" className="link"><span>Dashboard</span></Link>
            ): null}
          {!minimal ? <Link key="shops" to="/shops" className="link"><span>Shops</span></Link> : null}
          {!minimal ? <Link key="superannuation-calculators" to="/superannuation-calculators" className="link"><span>Super Calculators</span></Link> : null}
          {authentication?.user?.roundups_visible ? (
            !minimal ? (
              <Link key="settings-round-ups" to="/settings?tab=round-ups" className="link">
                <Tooltip
                  title={
                    roundupLinkedAccounts && roundupLinkedAccounts.length > 0
                      ? ''
                      : 'Round-Ups is our most impactful product. On average, users using Round-Ups earn over $50 of spare change for their super every month.'
                  }
                >
                  <Badge
                    variant="dot"
                    color="error"
                    invisible={roundupLinkedAccounts && roundupLinkedAccounts.length > 0}
                  >
                    <span>Round-Ups</span>
                  </Badge>
                </Tooltip>
              </Link>
            ) : null
          ) : null}


          {authentication?.user ? (
            <DialogTrigger
              setIsDialogMenuOpen={setIsDialogMenuOpen}
              trigger={
                <Avatar
                  name={`${authentication.user.shopper_fname} ${authentication.user.shopper_lname}`}
                  backgroundColor="#000"
                  color="#FFF"
                />
              }
              dialogContent={
                <div className="account-container">
                  <div className="header">
                    <h2 className="name">{`${authentication.user.shopper_fname} ${authentication.user.shopper_lname}`}</h2>
                  </div>
                  <Menu
                    items={[
                      { label: 'Shops', onClick: () => navigate('/shops') },
                      { 
                        label: (
                          <Tooltip
                            title={
                              roundupLinkedAccounts && roundupLinkedAccounts.length > 0
                                ? ''
                                : 'Round-Ups is our most impactful product. On average, users using Round-Ups earn over $50 of spare change for their super every month.'
                            }
                          >
                            <Badge
                              variant="dot"
                              color="error"
                              invisible={roundupLinkedAccounts && roundupLinkedAccounts.length > 0}
                            >
                              <span>Round-Ups</span>
                            </Badge>
                          </Tooltip>
                        ),
                        onClick: () => navigate('/settings?tab=round-ups'),
                      },
                      { label: 'Boost Settings', onClick: () => navigate('/settings') },
                      { label: 'Account', onClick: () => navigate('/account') },
                      { label: 'Transactions', onClick: () => navigate('/transactions') },
                      { label: 'Dashboard', onClick: () => navigate('/dashboard') },
                      { label: 'Super Calculators', onClick: () => navigate('/superannuation-calculators') },
                      { label: 'Logout',
                          onClick: () => {
                            log({ 'event': 'System-Logout', 'shopper_id': authentication.user.shopper_id });
                            dispatch(AuthenticationActions.logout());
                            dispatch(AppActions.unSetPartnerContent());
                            dispatch(RoundUpsActions.unSetRoundUpsShopperData());
                            dispatch(RoundUpsTransactionsActions.reSetRoundUpsTransactions());
                            dispatch(ShoppingActions.unsetShopperBoostProfile());
                            navigate('/login');
                          }
                      },
                      ...(authentication.user && minimal && app.hasBeforeInstallPromptBeenFired && !(window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) ? [{ label: 'Install App', onClick: () => onInstallButtonClicked() }] : [])
                    ]}
                  />
                </div>
              }
            />
          ) : !minimal ? ([
            <Link key="signup" to="/signup">
              <ActionButton text="Sign&nbsp;Up" large={true} />
            </Link>,
            <Link key="login" to="/login">
              <ActionButton text="Login" large={true} />
            </Link>
          ]) : (
                <Link key="signup-minimal" to="/signup">
                  <ActionButton text="Sign&nbsp;Up" large={true} />
                </Link>
              )
          }

        </div>
      </div>
    </NavigationBarWrapper>
  );
};

export default NavigationBar;
