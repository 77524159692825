import React, { useState, useEffect } from 'react';
import numeral from 'numeral';
import { useSelector } from 'react-redux';
import { ComposedChart, LineChart, Line, Sector, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, Label, Text } from 'recharts';
import Spinner from 'react-spinner-material';
import styled from 'styled-components';

import PopoverHover from 'SharedComponents/popover-hover';

import styles from './index.css';

const GraphLineBarComposedWrapper = styled.div`${styles}`;

const GraphLineBarComposed = ({data1}) => {

  console.log ("GraphLineBarComposed component triggered with table data1: ", data1);
  //console.log ("GraphLineBarComposed component triggered with table data2: ", data2);

  const WrappedText = styled(Text)`
  white-space: pre-wrap;
  text-align: center;
`;

  const formatLabel = (value) => {
    if (value === 'maleAveBalance') {
      return 'Ave Balance (Males)';
    } else if (value === 'femaleAveBalance') {
      return 'Ave Balance (Females)';
    } else if (value === 'currentSuperBalance') {
      return 'Current Super Balance';
    }

    if (typeof value == 'number') {
      return `Age (Yrs): ${value}`;
    }

    return value; // Default to returning the raw value
  };

  const formatValue = (value) => {
    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`; // Millions
    } else if (value >= 1000) {
      return `$${(value / 1000).toFixed(1)}k`; // Thousands
    }
    return `$${value.toString()}`; // Smaller numbers as is
  };

  const CustomTooltip = ({ active, payload, label, formatter, labelFormatter }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: 10 }}>
          <span className="label" style={{ display: 'block', marginBottom: 5 }}>{labelFormatter(label)}</span>
          {payload.map((entry, index) => {
            const [formattedValue, formattedLabel] = formatter(entry.value, entry.name);
            if (entry.name === 'currentSuperBalance' && entry.value === 0) {
              return null; // Hide currentSuperBalance when its value is 0
            }
            return (
              <span key={`tooltip-item-${index}`} style={{ display: 'block', margin: 0 }}>
                {formattedLabel}: {formattedValue}
              </span>
            );
          })}
        </div>
      );
    }
    return null;
  };
  


  return (
    <GraphLineBarComposedWrapper>
      
      <ResponsiveContainer width="100%" height="100%"> 
      <ComposedChart
          /* width={500}*/
          /* height={400} */ 
          data={data1}
          //data2={data2}

          margin={{
            top: 10,
            right: 10,
            bottom: 10,
            left: 40,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="i" scale="point" domain={[18, 80]}>
            <Label
              value="Age (Yrs)"
              position="outsideBottom"
              offset={-20}
              dy={20}
              style={{ fill: '#000', fontSize: 14, fontWeight: 'bold' }}
              />
          </XAxis>
          <YAxis 
            tickFormatter={formatValue}
          >
            <Label
              value="Super Balance ($)"
              angle={-90}
              position="outsideLeft"
              offset={20}
              dx={-50}
              style={{ fill: '#000', fontSize: 14, fontWeight: 'bold' }}
            />
          </YAxis>
          <Tooltip
            content={
              <CustomTooltip
                formatter={(value, name) => [formatValue(value), formatLabel(name)]}
                labelFormatter={(label) => formatLabel(label)}
              />
            }
          />

          <Legend
            formatter={(value) => formatLabel(value)}
            layout="horizontal"
            verticalAlign="bottom"
            align="center"
            wrapperStyle={{
              paddingTop: 20,
              fontSize: '14px',
              color: '#333',
            }}
          />
          <Line type="monotone" dataKey="maleAveBalance" dot={false} stroke="#ff7300" label="Ave Balance (Males)"/>
          <Line type="linear" dataKey="femaleAveBalance" dot={false} stroke="#413ea0" label="Ave Balance (Females)"/>
          <Bar dataKey="currentSuperBalance" barSize={80} fill="#ffba00" label="Current Super Balance"/>
        </ComposedChart>

      </ResponsiveContainer>
    </GraphLineBarComposedWrapper>
  );
};

export default GraphLineBarComposed;
