import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, useLocation } from 'react-router-dom';

import styles from './index.css';

const BottomNavigationWrapper = styled(BottomNavigation)`${styles}`;

function SimpleBottomNavigation({ user, roundupLinkedAccounts }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(location.pathname + location.search);

  React.useEffect(() => {
    setValue(location.pathname + location.search);
  }, [location]);

  return (
    <BottomNavigationWrapper
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        navigate(newValue);
      }}
      showLabels
      className="bottom-navigation"
      style={{ boxShadow: '0 -3px 3px rgba(0, 0, 0, 0.1)' }}
    >
      <BottomNavigationAction label={ user ? "Dashboard" : "Home"} value={ user ? "/dashboard" : "/"} 
        style={{
          color: value === "/dashboard" || value === "/" ? "#000000" : "#0000008a",
        }}
        icon={<i className="material-icons">{ user ? "dashboard" : "home"}</i>}
      />
      <BottomNavigationAction label="Shops" value="/shops" style={{ color: value === '/shops' ? '#000000' : '#0000008a' }} icon={<i className="material-icons">store_mall_directory</i>} />
      <BottomNavigationAction label="Super Calculators" value="/superannuation-calculators" style={{ color: value === '/superannuation-calculators' ? '#000000' : '#0000008a' }} icon={<i className="material-icons">calculate</i>} />

      {user ? (
        <BottomNavigationAction
          label="Round-Ups"
          value="/settings?tab=round-ups"
          style={{
            color: value === '/settings?tab=round-ups' ? '#000000' : '#0000008a',
          }}
          icon={
            <Tooltip
            title={
              roundupLinkedAccounts && roundupLinkedAccounts.length > 0
                ? ""
                : "Round-Ups is our most impactful product. On average users using Round-Ups earn over $50 of spare change for thier super every month."
            }
            >
              <Badge
                variant="dot" // Adds a dot as the notification
                invisible={roundupLinkedAccounts && roundupLinkedAccounts.length > 0} // Hide dot if Round-Ups is activated
              >
                <i className="material-icons">receipt</i>
              </Badge>
            </Tooltip>
          }
        />
      ) : null}

      {
        !user ? (
            <BottomNavigationAction label="Login" value="/login" style={{ color: value === '/login' ? '#000000' : '#0000008a' }} icon={<i className="material-icons">account_circle</i>} />
          ) : null
      }
    </BottomNavigationWrapper>
  );
}

//export default connect((state) => ({ user: state.authentication.user }))(SimpleBottomNavigation);
export default connect((state) => ({
  user: state.authentication.user,
  roundupLinkedAccounts: state.roundups.linkedAccounts, // Assume this value exists in Redux state
}))(SimpleBottomNavigation);
